<template>
	<footer
		v-if="isDataLoadingFinished && selectedFooterOption.type !== 'noFooter'"
		:class="{ notVisibleInMobile: !selectedFooterOption.visibleOnMobile }"
	>
		<div
			v-if="selectedFooterOption.type === 'copyrightOnly'"
			class="footer__copyright-only"
		>
			© INFOVINE Korea Corporation All Rights Reserved.
		</div>
		<div
			v-else-if="selectedFooterOption.type === 'default'"
			class="footer__all"
			:class="{ onshopdetail: route.name === 'ShopDetail'}"
		>
			<div class="footer__wrapper">
				<router-link class="footer__logo" to="/"
					><img src="@/assets/img/logo_w_new@2x.png" alt="logo"
				/></router-link>
				<div class="footer__links">
					<router-link to="/terms?type=1">이용약관</router-link>
					<span>|</span>
					<router-link to="/terms?type=2"
						>개인정보처리방침</router-link
					>
					<span>|</span>
					<router-link to="/solution">비즈니스 소개</router-link>
					<span>|</span>
					<button @click="toggleApplication(true)">제휴문의</button>
				</div>
				<div class="footer__bottom">
					상호명 : ㈜인포바인 | 대표이사 : 권성준<br />
					사업자등록번호 : <a onclick="return false">106-81-86362</a> <br />
					통신판매업신고번호:2020-서울영등포-0275<br />
					주소 : 서울특별시 영등포구 여의대로 24, 40층<br />
					고객센터 : <a onclick="return false">070-7549-4891</a><br /><br />
					© INFOVINE Korea Corporation All Rights Reserved.
				</div>
			</div>
		</div>
	</footer>
</template>

<script setup>
import { onMounted, ref, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
const store = useStore();
const route = useRoute();
const router = useRouter();
const isDataLoadingFinished = ref(
	computed(() => store.getters.getIsDataLoadingFinished)
);
const footerOptions = {
	//copyrightOnly: 페이지 이름만 있음
	//noFooter: 푸터 없음
	AccountLogin: { type: "copyrightOnly", visibleOnMobile: true },
	AccountCheckAdult: { type: "copyrightOnly", visibleOnMobile: true },
	AccountSNSJoin: { type: "copyrightOnly", visibleOnMobile: true },
	AccountTerms: { type: "copyrightOnly", visibleOnMobile: true },
	AccountResult: { type: "copyrightOnly", visibleOnMobile: true },
	Terms: { type: "copyrightOnly", visibleOnMobile: true },
	AccountCertify: { type: "noFooter", visibleOnMobile: true },
	ShopPayloading: { type: "noFooter", visibleOnMobile: true },
	ShopPurchase: { type: "default", visibleOnMobile: false },
	ShopInspecting: { type: "copyrightOnly", visibleOnMobile: true },
	ShopClosed: { type: "copyrightOnly", visibleOnMobile: true },
	ShopItemInvalid: { type: "noFooter", visibleOnMobile: true },
};
const selectedFooterOption = ref({
	type: "",
	visibleOnMobile: true,
});
const setFooterOption = async () => {
	await router.isReady();
	footerOptions[route.name] === undefined
		? (selectedFooterOption.value = {
				type: "default",
				visibleOnMobile: true,
		})
		: (selectedFooterOption.value = footerOptions[route.name]);
};
const toggleApplication = (boolean) => {
	store.commit("toggleApplicationPopup", boolean);
};
watch(route, () => {
	setFooterOption();
	
});
onMounted(async () => {
	setFooterOption();
});
</script>

<style scoped>
footer {
	position: relative;
	width: 100%;
}
footer.notVisibleInMobile {
	display: none;
}
.footer__copyright-only {
	border-top: 1px solid #d8d8d8;
	height: 140px;
	font-size: 12px;
	text-align: center;
	line-height: 140px;
	width: 100%;
	color: #888;
	background: #f8f9fa;
}

.footer__all {
	width: 100%;
	background: #333;
}

.footer__all.onshopdetail {
	height: 340px;
}
.footer__wrapper {
	display: flex;
	flex-direction: column;
	gap: 24px;
	width: 100%;
	padding: 25px;
}
.footer__all .footer__logo {
	display: block;
	width: 133px;
	height: 22px;
}

.footer__all .footer__logo img {
	width: 100%;
	object-fit: contain;
}

.footer__all .footer__links {
	display: inline-block;
	margin-left: 0px;
}

.footer__all .footer__links a,
.footer__all .footer__links button {
	width: auto;
	color: #fff;
	font-size: 13px;
	line-height: 0;
	height: auto;
	border: none;
	background: none;
	display: inline-block;
}

.footer__all span {
	display: inline-block;
	vertical-align: middle;
	color: #fff;
	margin: 0 4px;
	font-size: 10px;
	margin-top: -2px;
}

.footer__all .footer__bottom {
	color: #fff;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0px;
	font-weight: normal;
	margin-top: 0px;
}
.footer__all .footer__bottom span, .footer__all .footer__bottom a{
	text-decoration:none;
	color:inherit;
	font-size: 12px;
}
@media screen and (min-width: 760px) {
	footer {
		position: relative;
		width: 100%;
	}
	.footer__all {
		display: flex;
	}

	.footer__all.onshopdetail {
		height: 180px;
	}
	.footer__wrapper {
		display: block;
		width: 640px;
		margin: 0 auto;
		padding: 0;
		position: relative;
		height: 180px;
	}

	.footer__all .footer__logo {
		width: 200px;
		display: block;
		position: absolute;
		left: 60px;
		top: 60px;
	}

	.footer__all .footer__links {
		display: block;
		width: 318px;
		position: absolute;
		top: 18px;
		right: 0;
		font-size: 12px;
	}

	.footer__all .footer__bottom {
		width: 318px;
		position: absolute;
		top: 50px;
		right: 0;
		font-size: 12px;
		letter-spacing: 0;
		margin: 0;
	}
}

@media screen and (min-width: 1200px) {
	footer.notVisibleInMobile {
		display: block;
	}
	.footer__all {
		height: 180px;
	}
}
</style>