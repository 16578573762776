import axios from "axios";
import router from '@/router'
import {formatDate} from '@/util/global'
const apiURL = process.env.VUE_APP_API 
const calculateSalesPercent = (item) => {
	const originPrice = item.originprice;
	const salePrice = item.saleprice;
	if (item.saleinfo.saleoption === 0) {
		item.saleinfo.discountrate = Math.floor(
			((originPrice - salePrice) / originPrice) * 100
		)
		item.saleinfo.discountprice = item.saleprice
	}
};
export const shopPage = {
	namespaced:true, 
	state:{
		gameName:'',
		gameNameOnTitleTag:'',
		shopStatus:'ShopIsOn',
		bannerData:{},
		menus:[],
		itemListInfo:{},
		itemDetails:{
			itemSlideImages:[],
			itemid:0,
			gameUIName:'',//게임 이름
			name:'', //아이템 이름
			desc:'', // 아이템 설명
			originprice:0,
			saleprice:0,
			imageurls: [ //배너 이미지
				{
					imgid: 0,
					imgurl:''
				},
				{
					imgid:0,
					imgurl:''
				},
				{
					imgid:0,
					imgurl:''
				},
				{
					imgid:0,
					imgurl:''
				}
			],
			isAvailable:null,
			limitinfo: {
				limittype:0, // 11:일별  12:주별  13:월별  21: 계정당
				limitcnt:0, // limittype 기준 상 최대 구매 수량, 제한없을 경우 null
				oncemin:0, // 1회 최소 구매 수량
				oncemax:0, // 1회 최대 구매 수량
			},
			buyableQuantity:0,// 실질적으로 구매할 수 있는 수량
			remarkTagList:{
				amountString:'',
				durationString:'',
				limitString:''
			},		
		},
		itemRecommend:[],
		isTermsDetailActived:{},
		itemDetailBanner:[],
		orderRequestResponse:{},
		orderMethods:{
			payMethods:[],
			generalMethods:[],
			otherMethods:[]
		},
		paymentResponse:{
			code:'',
			url:'',
			orderID:''
		},
		refundTerm:'',
		usingGuide:null,
		faq:null,
		shopUseNotice:null,
		productInfo:null,
		isLimitTableOpened:false,
		currentURL:null,
		previousURL:null,
		pgType:1,
		documentTitle:'' //헤더나 글로벌 네비게이션에서 shop 버튼을 눌러서 샵 목록으로 갈 시 참조합니다. 
	},
	mutations:{
		setBannerData(state, payload){
			state.bannerData = payload
		},
		setShopStatus(state, status){
			state.shopStatus = status
		},
		setMenus(state, payload){
			payload.forEach((item) => {
				item.selected = false
			})
			state.menus = payload
		},
		selectMenu(state, menuid){
			state.menus.forEach(item => item.menuid === menuid ? item.selected = true : item.selected = false)
		},
		setShopInspectionStatus(state, payload){
			state.shopInspectionStatus = payload
		},
		setItemListInfo(state, payload){
			for(let i = 0; i<payload.list.length; i++){
				calculateSalesPercent(payload.list[i])
			}	
			state.itemListInfo = payload
		},
		addItemList(state, payload){
			const currentItemList = state.itemListInfo.list
			const additionalItemList = payload.list
			for(let i = 0; i<additionalItemList.length; i++){
				calculateSalesPercent(additionalItemList[i])
			}
			state.itemListInfo = payload
			state.itemListInfo.list = [...currentItemList, ...additionalItemList]
		},
		setItemQuantity(state, payload) {
			state.itemDetails.quantity = Number(payload);
		},
		setItemDetails(state, payload){
			const itemData = payload
			const itemSlideImages = payload.imageurls.filter(item => item.orderby !== 0).map(item =>{
				return {imgid:item.imgid, bannerimg:item.imgurl}
			}) 
			/* 아이템의 회당 최대 구매 회수와 아이템의 총 수량 설정
				회당 최대 구매 회수가 아이템의 총 수량을 넘어서면 아이템의 총 수량을 리턴하고, 
				아닐 경우 회당 최대 구매 회수를 리턴합니다.
			*/
			const buyableQuantity = () =>{
				if(itemData.limitinfo.limitcnt === 0 ){
					return itemData.limitinfo.oncemax
				}
				else if (itemData.limitinfo.limitcnt < itemData.limitinfo.oncemax){
					return itemData.limitinfo.limitcnt
				}
				else if(itemData.limitinfo.limitcnt >= itemData.limitinfo.oncemax){
					return itemData.limitinfo.oncemax
				}
			}
			const initialQuantity = () =>{
				return itemData.limitinfo.oncemin || 1 // 없으면 1
			}
			const returnLimitInfo = () =>{
				if(!itemData.limitinfo){
					return {limitcnt: 999, oncemax:999,}
				}
				else if(itemData.limitinfo.limitcnt === null ){
					itemData.limitinfo.limitcnt = 999
					return itemData.limitinfo
				}
				else{
					return itemData.limitinfo
				}
			}
			const returnAmountString = () =>{
				if(itemData.limitinfo){
					const limitTypeString = {
						'11':'매일, ',
						'12':'매주, ',
						'13':'매월, ',
						'21':''
					}
					return itemData.limitinfo.limittype ? 
					`${limitTypeString[itemData.limitinfo.limittype]}계정당 ${itemData.limitinfo.limitcnt}` : ''
				}
				else{
					return ''
				}
			}
			//아이템 구매기간 설정
			const returnDurationString = () =>{
				if(itemData.startdate && itemData.enddate){	
					return `판매일 : ${formatDate(itemData.startdate, 'ko-KR')} ~ ${formatDate(itemData.enddate, 'ko-KR')}`
				}
				else{
					return ''
				}
			}
			calculateSalesPercent(itemData)
			itemData.itemSlideImages = itemSlideImages // 이미지 추가 
			itemData.remarkTagList = { //아이템 구매 제한, 날짜 제한 등 추가
				amountString:returnAmountString(),
				durationString:returnDurationString(),
				limitString:''
			}
			itemData.limitinfo = returnLimitInfo()
			itemData.buyableQuantity = buyableQuantity() //실제로 구매할 수 있는 최대 수량 추가
			itemData.quantity = initialQuantity() //수량 추가
			state.itemDetails = itemData
		},
		setDocumentTitle(state, title){
			state.documentTitle = title // 헤더나 글로벌 네비게이션에서 shop 버튼을 눌러서 샵 목록으로 갈 시 참조합니다. 
			document.title = title
		},
		setGameName(state, gameName){
			state.gameName = gameName
		},
		setItemDetailsRecommend(state, payload){
			state.itemRecommend = payload
		},
		setUsingGuide(state, payload){
			state.usingGuide = payload
		},
		setFAQ(state, payload){
			state.faq = payload
		},
		setShopUseNotice(state, payload){
			state.shopUseNotice = payload
		},
		setProductInfo(state, payload){
			state.productInfo = payload
		},
		setOrderRequestResponse(state, payload){
			state.orderRequestResponse = payload
		},
		setOrderMethods(state, payload){
			const methodsByPaykind = {
				'1':[],
				'2':[],
				'3':[]
			}
			//페이결제
			payload.forEach(item => { 
				methodsByPaykind[`${item.paykind}`].push(item)
				try{ // 이미지 파일이 있으면 추가
					if(item.paykind === 1){
						item.icon = require(`@/assets/img/ic_${item.paymethod}@2x.png`)
					}
				}
				catch(e){
					console.log(e)
				}
				
			})
			state.orderMethods.payMethods = methodsByPaykind['1']
			state.orderMethods.generalMethods =  methodsByPaykind['2']
			state.orderMethods.otherMethods = methodsByPaykind['3']
		},
		setPaymentResponse(state, payload){
			state.paymentResponse.code = payload.code
			state.paymentResponse.url = payload.data.mobile_url
			state.paymentResponse.orderID = payload.data.order_no || payload.data.orderid
		},
		setOrderID(state, orderid){
			state.orderID = orderid
		},
		setRefundTerm(state, payload){
			state.refundTerm = payload
		},
		setIsLimitTableOpened(state){
			state.isLimitTableOpened = !state.isLimitTableOpened
		},
		setURLs(state, url){
			state.currentURL = apiURL+url.currentURL
			state.previousURL = apiURL+url.previousURL
		},
		setPGType(state, type){
			state.pgType = type
		}
	},
	actions: {
		async checkShopStatusOnly({commit, dispatch}, gameName){
			try{
				const bannerData = await axios.get(`${apiURL}/api/v1/games/${gameName}/banners`)
				const isShopInspecting = bannerData.data.data.company.status === 1 && bannerData.data.data.status === 2 //샵 점검 상태
				const isShopClosed = bannerData.data.data.company.status === 2 || bannerData.data.data.status === 3 //샵 운영 종료 상태
				if(isShopInspecting){
					commit('setBannerData', bannerData.data.data)
					commit('setShopStatus', 'ShopInspecting')
				}
				else if(isShopClosed){
					commit('setBannerData', bannerData.data.data)
					commit('setShopStatus', 'ShopClosed')
				}
				else{
					commit('setShopStatus', 'ShopIsOn')
				}
			}
			catch(code){
				dispatch('popupProcessor/setSelectedPopupByCode', code, {root:true})
			}
		},
		//배너와 메뉴 데이터를 한번에 가져오기
		async getShopData({commit, dispatch}, payload) {
			await axios.get(`${apiURL}/api/v1/games/${payload.gameName}/banners`)
			.then(bannerData =>{
				const isShopInspecting = bannerData.data.data.company.status === 1 && bannerData.data.data.status === 2 //샵 점검 여부
				const isShopClosed = bannerData.data.data.company.status === 2 || bannerData.data.data.status === 3//운영주체 운영 종료여부				
				if(isShopInspecting){
					commit('setBannerData', bannerData.data.data)
					throw 'ShopInspecting'
				}
				else if(isShopClosed){
					commit('setBannerData', bannerData.data.data)
					throw 'ShopClosed'
				}
				else if(bannerData.data.code !== 'S0000'){
					throw bannerData.data.code
				}
				commit('setBannerData', bannerData.data.data)
				return axios.get(`${apiURL}/api/v1/games/${payload.gameName}/menus`)
			})
			.then(menuData =>{
				if(menuData.data.code !== 'S0000'){
					throw menuData.data.code
				}
				else if(menuData.data.data.length === 0 && !localStorage.getItem('menuid')){
					return false
				}
				else{
					commit('setMenus', menuData.data.data)
					return localStorage.getItem('menuid') ? parseInt(localStorage.getItem('menuid')) : menuData.data.data[0].menuid
				}
			})
			.then(menuid =>{
				if(menuid){
					return axios.post(`${apiURL}/api/v1/games/${payload.gameName}/items`, {menuid, pageNum:1, pageSize:payload.pageSize})
				}
				else{
					commit("finishDataLoading", true, {root:true})
					return false 
				}
			})
			.then(response =>{
				if(!response){
					return 
				}
				commit('setItemListInfo', response.data.data) 
				commit("finishDataLoading", true, {root:true})
			})
			.catch(code =>{
				if(code !== 'ShopInspecting' && code !== 'ShopClosed'){
					dispatch('popupProcessor/setSelectedPopupByCode', code, {root:true})
				}
				else{
					commit('setShopStatus', code)
				}
			})
		},
		async getGuides({commit, dispatch}, payload){
			const guideType = payload.guideType
			const guideName = payload.guideName
			try{
				const response = await axios.get(`${apiURL}/api/v1/games/${payload.gameName}/guide?gtype=${guideType}`)
				if(response.data.code === 'S0000'){
					commit(`set${guideName}`, response.data.data[0])
					return response.data.data.length > 0
				}	
				else{
					throw response.data.code
				}
			}
			catch(code){
				dispatch('popupProcessor/setSelectedPopupByCode', code, {root:true})
			}
		},
		async getProductInfo({commit, dispatch}, itemid){
			try{
				const response = await axios.get(`${apiURL}/api/v1/games/item/noti?itemid=${itemid}`)
				if(response.data.code === 'S0000'){
					commit(`setProductInfo`, response.data.data)
				}	
				else{
					throw response.data.code
				}
			}
			catch(code){
				dispatch('popupProcessor/setSelectedPopupByCode', code, {root:true})
			}
		},
		async getItemListInfoByTabMenu({ commit, dispatch }, payload) {
			try{
				// 아이템 목록 초기화 및 불러오기
				const itemData = await axios.post(`${apiURL}/api/v1/games/${payload.gameName}/items`, payload.searchOption);
				if(itemData.data.code !== 'S0000'){
					throw itemData.data.code
				}
				else if(itemData.data.code === 'S0000' && itemData.data.data.list.length === 0){
					return false
				}
				else{
					commit("setItemListInfo", itemData.data.data)
					commit('selectMenu', payload.searchOption.menuid)
					return true
				}
			}
			catch(code){
				dispatch('popupProcessor/setSelectedPopupByCode', code, {root:true})
				return false
			}
		},
		async getMoreItemList({ commit, dispatch }, payload) {
			try{// 기존 아이템 목록에서 다른 아이템 목록 추가
				const itemData = await axios.post(
					`${apiURL}/api/v1/games/${payload.gameName}/items`,
					payload.searchOption
				);
				if(itemData.data.code !== 'S0000'){
					throw itemData.data.code
				}
				else{
					commit("addItemList", itemData.data.data);
				}
			}
			catch(code){
				dispatch('popupProcessor/setSelectedPopupByCode', code, {root:true})
			}
		},
		// detail async-wait 모드
		async getItemDetails({commit, dispatch}, payload){ 
			const isIDNaN = isNaN(payload.gameid) || isNaN(payload.itemid)
			const isIDZero = payload.gameid === 0 || payload.itemid === 0
			try{
				if (isIDNaN || isIDZero) {
					throw 'invalidParams'
				}
				const accesskeyString = payload.accesskey ? `&accesskey=${payload.accesskey}` : ''
				const response = await Promise.all([			
					axios.get(`${apiURL}/api/v1/games/item?gameid=${payload.gameid}&itemid=${payload.itemid}${accesskeyString}`),
					axios.get(`${apiURL}/api/v1/games/item/recommend?gameid=${payload.gameid}&itemid=${payload.itemid}`),
				])
				response.forEach(item =>{
					if(item.data.code !== 'S0000'){
						throw item.data.code
					}
				})
				commit('setItemDetails', response[0].data.data) 
				commit('setItemDetailsRecommend', response[1].data.data) 
				commit('setGameName', response[0].data.data.urlname)
			}
			catch(code){
				if(code === 'invalidParams'){
					await dispatch('checkShopDetailParamError', payload)
					await router.push({path:'/shop/item-invalid'})
				}
				else if(code === 'E0400' || code === 'ES3101' || code?.response?.status === 405){
					await router.push({path:'/shop/item-invalid'})
				}
				else{
					dispatch('popupProcessor/setSelectedPopupByCode', code, {root:true})
				}
			}
		},
		async getOrderRequestResponse({commit}, payload){
			try{
				const response = await axios.post(`${apiURL}/api/v1/order`, payload)
				if(response.data.code !== 'S0000'){
					throw response.data.code
				}
				else if(response.data.data.resultcd !== 'S0000'){
					throw response.data.data.resultcd
				}
				const orderRequestResponse = response.data.data
				localStorage.setItem("itemTitle", orderRequestResponse.itemTitle)
				localStorage.setItem("itemRefImg", orderRequestResponse.imgUrl)
				localStorage.setItem("memPoint", orderRequestResponse.memPoint)
				localStorage.setItem("pointRate", orderRequestResponse.pointRate)
				localStorage.setItem("pointMin", Math.floor(orderRequestResponse.pointMin))
				localStorage.setItem("pointMax", Math.floor(orderRequestResponse.pointMax))
				localStorage.setItem("pointLimitRate", Math.floor(orderRequestResponse.pointLimitRate))
				localStorage.setItem("isUsePoint", orderRequestResponse.isUsePoint)
				//tester PGType 설정
				commit('setPGType', orderRequestResponse.isTester === 1 ? 3 : 1)
				return {code:orderRequestResponse.resultcd, ...orderRequestResponse}
			}
			catch(code){
				return {code}
			}
		},
		async getOrderMethods({commit, dispatch, state}, gameid){
			const pgType = state.pgType
			try{
				const response = await axios.get(`${apiURL}/api/v1/order?gameid=${gameid}&pgtype=${pgType}`)
				if(response.data.code !== 'S0000'){
					throw response.data.code
				}
				commit('setOrderMethods', response.data.data)
			}
			catch(code){
				dispatch('popupProcessor/setSelectedPopupByCode', code, {root:true})
			}
		},
		async getPointCancelResult(_, orderid){
			const response = await axios.put(`${apiURL}/api/v1/pointpay/cancel?orderid=${orderid}`)
			return response.data.code
		},
		async getRefundTerm({commit}){
			const response = await axios.get(`${apiURL}/api/v1/user/agreements?setid=2`)
			commit('setRefundTerm', response.data.data[0])
		},
		async checkShopDetailParamError({state, dispatch}, payload){
			try{
				const response = await axios.put(`${apiURL}/api/v1/user/error`, {
					currentUrl:state.currentURL,
					previousUrl:state.previousURL
				})
				console.log(response)
				console.error('NaN detected:', {
					gameid: payload.gameid,
					itemid: payload.itemid,
					url: window.location.href,
					timestamp: new Date().toISOString(),
				});
			}
			catch(code){
				dispatch('popupProcessor/setSelectedPopupByCode', code, {root:true})
			}
		},
		getURLs({commit}, urlObj){
			commit('setURLs', urlObj)
		},
	},
	getters:{
		getBannerData(state){
			return state.bannerData
		},
		getShopStatus(state){
			return state.shopStatus
		},
		getGameName(state){
			return state.gameName
		},
		getMenus(state) {
			return state.menus;
		},
		getItemData(state) {
			return state.itemData
		},
		getItemListInfo(state){
			return {...state.itemListInfo}
		} ,
		getItemDetails(state) {
			return state.itemDetails;
		},
		getItemRecommend(state){
			return state.itemRecommend
		},
		getOrderRequestResponse(state){
			return state.orderRequestResponse
		},
		getOrderMethods(state){
			return state.orderMethods
		},
		getPurchaseData(state){
			return state.purchaseData
		},
		getPaymentResponse(state){
			return state.paymentResponse
		},
		getRefundTerm (state){
			return state.refundTerm
		},
		getUsingGuide(state){
			return state.usingGuide
		},
		getFAQ(state){
			return state.faq
		},
		getShopUseNotice(state){
			return state.shopUseNotice
		},
		getProductInfo(state){
			return state.productInfo
		},
		getIsLimitTableOpened(state){
			return state.isLimitTableOpened
		},
		getPGType(state){
			return state.pgType
		},
		getDocumentTitle(state){ //헤더나 글로벌 네비게이션에서 shop 버튼을 눌러서 샵 목록으로 갈 시 참조합니다. 
			return state.documentTitle
		}
	},
};
