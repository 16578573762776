<template>
	<div class="layer-popup__background" v-if="blackListDetails.isUserOnBlackList">
		<div class="layer-popup">
			<h2>알림 <img src="@/assets/img/btn_close_w-black@2x.png" class="layer-popup__close" alt="close" @click="initBlackListStatus"></h2>
			<p class="layer-popup__notice">
				게임브릿지에서 안내 드립니다.<br/>
				<strong>회원님의 계정</strong>은 <strong>이용약관 위반</strong>으로<br/>
				<strong>서비스 이용</strong>이 일시적으로 <strong>제한</strong> 됩니다.
			</p>
			<section>
				<table>
					<tr>
						<td class="title">제한일시</td>
						<td>{{ blackListDetails.startdate}}부터 해제 시 까지</td>	
					</tr>
					<tr>
						<td class="title">제한사유</td>
						<td>
							<div v-html="blackListDetails.reason"></div>
						</td>	
					</tr>
				</table>
				<p class="layer-popup__inquiry-info">
					※ 이용 정지에 대한 소명 및 이의신청은 아래 메일주소로 문의를 남겨주세요.<br/>
					gb_support@infovine.co.kr
				</p>
			</section>	
			
		</div>
	</div>
</template>

<script setup>
	import { computed, ref} from 'vue'
	import { useStore } from 'vuex'
	const store = useStore()
	const blackListDetails = ref(computed(() => store.getters.getBlackListDetails))
	const initBlackListStatus = () =>{
		window.open('/logout', '_self')
	}
</script>

<style scoped>
	.layer-popup__background {
		background: rgba(0, 0, 0, 0.5);
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 100;
	}

	.layer-popup {
		width: calc(100% - 20px);
		min-width:300px;
		max-width: 564px;
		min-height: 280px;
		border-radius: 8px;
		background: #fff;
		margin: 0 auto;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		padding-bottom:24px;
	}

	.layer-popup h2 {
		width: 100%;
		height: 50px;
		border-bottom: 1px solid #d8d8d8;
		line-height: 48px;
		font-size: 18px;
		font-weight: bold;
		letter-spacing: -0.9px;
		text-align: center;
		color: #17191d;
	}
	.layer-popup h2 .layer-popup__close{
		width:24px;
		height:24px;
		position: absolute;
		top:12px; 
		right:12px;
	}
	.layer-popup .layer-popup__notice {
		width: 100%;
		margin-top: 24px;
		text-align: center;
		font-size: 16px;
		letter-spacing: -0.8px;
	}
	.layer-popup section{
		margin:24px auto 0;
		width:100%;
		padding:0 10px;
	}
	.layer-popup section table {
		width:100%;
		max-width:300px;
		margin:0 auto;
		border-collapse: collapse;
	}
	.layer-popup section table td{
		text-align: left;
		border:1px solid #d8d8d8;
		padding:12px;
		line-height: 1.14;
		letter-spacing: -0.7px;
		font-weight: normal;
		font-size:13px;
	}
	.layer-popup section table .title{
		background: #fcefee;
		text-align: center;
		font-weight: bold;
		width: 76px;
	}
	.layer-popup__inquiry-info{
		font-size: 12px;
		line-height: 1.5;
		letter-spacing: -0.6px;
		color: #17191d;
		margin-top:16px;
		max-width:424px;
		margin:0 auto;
	}
	@media screen and (min-width:760px) {
		.layer-popup{
			padding-bottom:34px;
		}
		.layer-popup .layer-popup__notice {
			font-size:18px;

		}
		.layer-popup section {
			width:424px;
			padding:0;
		}
		.layer-popup section table {
			max-width:324px;
			margin:38px auto 0;
		}
		.layer-popup section table td{
			font-size:14px;
		}
		.layer-popup__inquiry-info{
			font-size: 14px;
			line-height: 2;
			letter-spacing: -0.7px;
			color: #17191d;
			margin-top:38px;
		}
	}
</style>